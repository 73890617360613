import useFetchProjects from '../../hooks/project/useFetchProjects';
import { useProjectStore } from '../../hooks/project/useProjectStore';
import { useEffect, useMemo, useState } from 'react';
import type { Option } from '../ui/autocomplete';
import { Popover, PopoverContent, PopoverTrigger } from '../ui/popover';
import { Button } from '../ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '../ui/command';
import { Check, ChevronsUpDown } from 'lucide-react';
import { cn } from '../../lib/utils';

const SelectProject = ({ isSidebarOpen }) => {
  useFetchProjects();
  const { projects, curProjectUuid, setCurProjectUuid } = useProjectStore(
    (state) => ({
      projects: state.projects,
      curProjectUuid: state.curProjectUuid,
      setCurProjectUuid: state.setCurProjectUuid,
    })
  );

  const [selectedProjectedUuid, setSelectedProjectedUuid] = useState(null);

  useEffect(() => {
    if (!selectedProjectedUuid && curProjectUuid) {
      setSelectedProjectedUuid(curProjectUuid);
    }
  }, [curProjectUuid, selectedProjectedUuid]);

  const handleSelectProject = (uuid: string) => {
    setSelectedProjectedUuid(uuid);
    setCurProjectUuid(uuid);
  };

  const filteredProjects: Option[] = useMemo(() => {
    // convert to type Option { label: string (name), value: string (uuid) }
    return Object.values(projects || {})
      .filter((p) => !p.isArchived)
      .map((p) => ({
        label: p.name,
        value: p.uuid,
      }));
  }, [projects]);

  if (Object.keys(projects || {}).length === 0 || !isSidebarOpen) {
    return;
  }

  return (
    <div>
      <p className="text-md ml-1 mb-1">Projects:</p>
      <ProjectList
        projects={filteredProjects}
        handleSelectProject={handleSelectProject}
        selectedProjectUuid={selectedProjectedUuid}
      />
    </div>
  );
};

interface ProjectListProps {
  projects: Option[];
  handleSelectProject: (uuid: string) => void;
  selectedProjectUuid: string;
}

export function ProjectList({
  projects,
  handleSelectProject,
  selectedProjectUuid,
}: ProjectListProps) {
  const [open, setOpen] = useState(false);

  const selectedProject = projects.find(
    (project) => project.value === selectedProjectUuid
  );

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-[180px] truncate justify-between"
        >
          {selectedProject ? selectedProject.label : 'Select project...'}
          <ChevronsUpDown className="opacity-50 w-4 h-4" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[230px] p-1">
        <Command>
          <CommandInput placeholder="Search projects..." className="h-9" />
          <CommandList>
            <CommandEmpty>No project found.</CommandEmpty>
            <CommandGroup>
              {projects.map((project) => (
                <CommandItem
                  key={project.value}
                  value={project.label}
                  onSelect={() => {
                    handleSelectProject(project.value);
                    setOpen(false);
                  }}
                >
                  {project.label}
                  <Check
                    className={cn(
                      'ml-auto',
                      selectedProjectUuid === project.value
                        ? 'opacity-100'
                        : 'opacity-0'
                    )}
                  />
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}

export default SelectProject;
