import { useApiClient } from '../useApiClient';
import { useExperimentStore } from '../../store/useExperimentStore';
import { useCallback } from 'react';
import {
  getProjectsEndpoint,
  updateProjectEndpoint,
} from '../../utils/constants';
import type { Project } from '../../utils/types';
import { useAuthManager } from '../auth/useAuthManager';

export const useProjectAPIEndpoints = () => {
  const { getRequest, postRequest } = useApiClient();

  const { setError, setLoading } = useExperimentStore((state) => ({
    setError: state.setError,
    setLoading: state.setLoading,
  }));

  const { user } = useAuthManager();
  const org_id = user?.organizationMemberships?.[0]?.organization.id;

  const getProjects = useCallback(async (): Project[] => {
    setLoading(true);
    try {
      if (org_id !== undefined) {
        const response = await getRequest(getProjectsEndpoint(org_id));
        return response.data?.map((projBackend) => ({
          uuid: projBackend.uuid,
          name: projBackend.name,
          isArchived: projBackend?.is_archived ?? false,
          createdAt: projBackend.created_at,
        }));
      }
      return [];
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [getRequest, setError, setLoading, org_id]);

  const createProjectAPI = useCallback(
    async (name: string): Promise<Project> => {
      setLoading(true);
      try {
        const response = await postRequest(getProjectsEndpoint(org_id), {
          name: name,
        });
        return {
          uuid: response.data.uuid,
          name: response.data.name,
          isArchived: false,
          createdAt: response.data.created_at,
        };
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    },
    [postRequest, setError, setLoading, org_id]
  );

  const editProjectName = useCallback(
    async (name: string, projectUuid: string): Promise<boolean> => {
      setLoading(true);
      try {
        const response = await postRequest(
          updateProjectEndpoint(org_id, projectUuid),
          {
            name: name,
          }
        );
        return response.data;
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    },
    [postRequest, setError, setLoading, org_id]
  );

  const archiveProject = useCallback(
    async (projectUuid: string): Promise<boolean> => {
      setLoading(true);
      try {
        const response = await postRequest(
          `${updateProjectEndpoint(org_id, projectUuid)}/archive`
        );
        return response.data;
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    },
    [postRequest, setError, setLoading, org_id]
  );

  return { getProjects, createProjectAPI, editProjectName, archiveProject };
};
