import type { ProjectsObj } from '../../utils/types';
import { Project } from '../../utils/types';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

export interface ProjectInitialState {
  error: string | null;
  loading: boolean;
  projects: ProjectsObj;
  curProjectUuid: string | null;
}

const initialState: ProjectInitialState = {
  error: null,
  loading: false,
  projects: {},
  curProjectUuid: null,
};

interface ProjectStateStore extends ProjectInitialState {
  setError: (error: string | null) => void;
  setLoading: (loading: boolean) => void;
  setProjects: (projects: ProjectsObj) => void;
  updateProject: (project: Project) => void;
  setCurProjectUuid: (projectUUID: string) => void;
}

export const useProjectStore: () => ProjectStateStore = create(
  immer((set) => ({
    ...initialState,
    setError: (error: string) =>
      set((state) => {
        state.error = error;
      }),
    setLoading: (loading: boolean) =>
      set((state) => {
        state.loading = loading;
      }),
    setProjects: (projects: ProjectsObj) => {
      set((state) => {
        state.projects = projects;
      });
    },
    updateProject: (project: Project) => {
      set((state) => {
        state.projects[project.uuid] = project;
      });
    },
    setCurProjectUuid: (projectUUID: string) => {
      set((state) => {
        state.curProjectUuid = projectUUID;
      });
    },
  }))
);
