import {
  IconButton,
  Spacer,
  useColorMode,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import {
  AppShell,
  NavGroup,
  NavItem,
  Sidebar,
  SidebarOverlay,
  SidebarSection,
} from '@saas-ui/react';
import { FiChevronsLeft, FiChevronsRight, FiHome } from 'react-icons/fi';
import { LuFolders, LuKeySquare } from 'react-icons/lu';
import {
  BILLING_ROUTE,
  ENABLE_ANALYTICS,
  ENABLE_CLERK_AUTHENTICATION,
  HIDE_BILLING,
  HOME_ROUTE,
  PROJECT_MANAGEMENT_ROUTE,
  ROUTE_DETAILS,
  SETTINGS_ROUTE,
  SHARED_NAME,
} from '../../utils/constants';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { GiBookCover } from 'react-icons/gi';
import { useTheme } from './theme-provider';
import { FaMoneyBill1 } from 'react-icons/fa6';
import { MoonIcon, SunIcon } from './ThemeModeToggler';
import SelectProject from './SelectProject';
import { AiFillHome } from 'react-icons/ai';
import { FreeTierCard } from './FreeTierCard';
import useScreenSize from '../../hooks/useScreenSize';
import { UserButtonWithAnalytics } from './UserButtonWithAnalytics';
import { UserButton } from './UserButton';
import { LogoutNavWithAnalytics } from './LogoutNavWithAnalytics';
import { LogoutNav } from './LogoutNav';
import { useAuthManager } from '../../hooks/auth/useAuthManager';
import { SignedIn, SignedOut } from '../common/Auth';
import OrgSwitcherSection from './OrgSwitcherSection';
import { useUserContext } from '../../store/UserContext';

export const navItems = [
  { icon: <AiFillHome />, name: 'Home', route: HOME_ROUTE, protected: true },
  {
    icon: ROUTE_DETAILS.Playground.icon(),
    name: ROUTE_DETAILS.Playground.name,
    route: ROUTE_DETAILS.Playground.route,
    protected: true,
  },
  {
    icon: ROUTE_DETAILS.Datasets.icon(),
    name: ROUTE_DETAILS.Datasets.name,
    route: ROUTE_DETAILS.Datasets.route,
    protected: true,
  },
  {
    icon: ROUTE_DETAILS.Experiments.icon(),
    name: ROUTE_DETAILS.Experiments.name,
    route: ROUTE_DETAILS.Experiments.route,
    protected: true,
  },
  {
    icon: ROUTE_DETAILS.Logs.icon(),
    name: ROUTE_DETAILS.Logs.name,
    route: ROUTE_DETAILS.Logs.route,
    protected: true,
  },
  {
    icon: ROUTE_DETAILS.Annotations.icon(),
    name: ROUTE_DETAILS.Annotations.name,
    route: ROUTE_DETAILS.Annotations.route,
    protected: true,
  },
  {
    icon: ROUTE_DETAILS.Deployments.icon(),
    name: ROUTE_DETAILS.Deployments.name,
    route: ROUTE_DETAILS.Deployments.route,
    protected: true,
  },
];

export const settings = {
  icon: <LuKeySquare />,
  name: 'API Keys',
  route: SETTINGS_ROUTE,
  protected: true,
};

export const billing = {
  icon: <FaMoneyBill1 />,
  name: 'Billing',
  route: BILLING_ROUTE,
  protected: true,
};

export const projects = {
  icon: <LuFolders />,
  name: 'Projects',
  route: PROJECT_MANAGEMENT_ROUTE,
  protected: true,
};

const InternalNavItem = memo(({ icon, item, toNavigate, onClose }) => {
  const isSelected = window.location.pathname === item.route;

  const handleNavClick = useCallback(() => {
    if (onClose) onClose();
    toNavigate(item.route);
  }, [item.route, toNavigate, onClose]);

  return (
    <NavItem icon={icon} isActive={isSelected} onClick={handleNavClick}>
      {item.name}
    </NavItem>
  );
});

function CustomUserButton({
  onOpen,
  organization_exists,
  setOrganizationExists,
}) {
  const { openOrganizationProfile } = useAuthManager();

  const handleOnClickOrg = () => {
    if (organization_exists) {
      openOrganizationProfile();
    } else {
      onOpen();
    }
  };

  return (
    <>
      {ENABLE_ANALYTICS === true ? (
        <UserButtonWithAnalytics
          organization_exists={organization_exists}
          setOrganizationExists={setOrganizationExists}
          handleOnClickOrg={handleOnClickOrg}
        />
      ) : (
        <UserButton
          organization_exists={organization_exists}
          setOrganizationExists={setOrganizationExists}
          handleOnClickOrg={handleOnClickOrg}
        />
      )}
    </>
  );
}

export default function CollapsibleSidebar({ children }) {
  const { isOpen, onToggle } = useDisclosure({
    defaultIsOpen: true,
  });

  const { user } = useAuthManager();
  const { subscriptionPlan } = useUserContext();
  const orgId = user?.organizationMemberships?.[0]?.organization?.id;

  const navigate = useNavigate();

  const location = useLocation();

  const screenSize = useScreenSize();
  const smallFreeTierCard = screenSize.height <= 900;
  const hideFreeTierCard = screenSize.height <= 710;

  const [onOnboardingPath, setOnOnboardingPath] = useState(false);

  useEffect(() => {
    setOnOnboardingPath(location.pathname.startsWith('/welcome'));
  }, [location]);

  const navigateToDocs = () => {
    window.open('https://docs.parea.ai', '_blank');
  };

  const SwitchIcon = useColorModeValue(MoonIcon, SunIcon);
  const { colorMode, toggleColorMode } = useColorMode();
  const { setTheme } = useTheme();

  const handleColorMode = (mode) => {
    toggleColorMode();
    if (SwitchIcon === MoonIcon) {
      setTheme('dark');
    } else {
      setTheme('light');
    }
  };

  const {
    isOpen: isProfileOpen,
    onOpen: onProfileOpen,
    onClose: onProfileClose,
  } = useDisclosure();
  const [organization_exists, setOrganizationExists] = useState(false);

  useEffect(() => {
    if (organization_exists && isOpen) {
      onProfileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization_exists]);

  return (
    <>
      <AppShell
        minH="100vh"
        className="overflow-y-hidden max-h-screen"
        sidebar={
          <>
            {!onOnboardingPath && (
              <Sidebar
                toggleBreakpoint={false}
                variant={isOpen ? 'default' : 'compact'}
                transition="width"
                transitionDuration="normal"
                width={isOpen ? '200px' : '14'}
                minWidth="auto"
              >
                <SidebarSection direction={isOpen ? 'row' : 'column'}>
                  <SignedIn>
                    <CustomUserButton
                      onOpen={onProfileOpen}
                      organization_exists={organization_exists}
                      setOrganizationExists={setOrganizationExists}
                    />
                  </SignedIn>
                  <Spacer />

                  <IconButton
                    onClick={onToggle}
                    variant="ghost"
                    size="sm"
                    icon={isOpen ? <FiChevronsLeft /> : <FiChevronsRight />}
                    aria-label="Toggle Sidebar"
                  />
                </SidebarSection>

                {ENABLE_CLERK_AUTHENTICATION === true && (
                  <OrgSwitcherSection
                    isOpen={isOpen}
                    isProfileOpen={isProfileOpen}
                    onProfileClose={onProfileClose}
                    organization_exists={organization_exists}
                  />
                )}

                <SidebarSection direction={isOpen ? 'row' : 'column'}>
                  <SignedIn>
                    <SelectProject isSidebarOpen={isOpen} />
                  </SignedIn>
                </SidebarSection>

                <SidebarSection flex="1" overflowY="auto" overflowX="hidden">
                  <NavGroup>
                    {navItems.map((item) => {
                      if (item.name === SHARED_NAME && !orgId) {
                        return null;
                      } else if (item.protected) {
                        return (
                          <SignedIn key={item.name}>
                            <InternalNavItem
                              icon={item.icon}
                              item={item}
                              toNavigate={navigate}
                            />
                          </SignedIn>
                        );
                      } else {
                        return (
                          <SignedOut key={item.name}>
                            <InternalNavItem
                              icon={<FiHome />}
                              item={item}
                              toNavigate={navigate}
                            />
                          </SignedOut>
                        );
                      }
                    })}
                  </NavGroup>
                </SidebarSection>

                {subscriptionPlan === 'free' && (
                  <SignedIn>
                    {isOpen && !hideFreeTierCard && HIDE_BILLING === false && (
                      <SidebarSection>
                        <FreeTierCard isSmall={smallFreeTierCard} />
                      </SidebarSection>
                    )}
                  </SignedIn>
                )}

                <SidebarSection direction={isOpen ? 'row' : 'column'} pb="2">
                  <NavGroup>
                    <SignedIn>
                      <InternalNavItem
                        icon={settings.icon}
                        item={settings}
                        toNavigate={navigate}
                      />
                      {HIDE_BILLING === false && (
                        <InternalNavItem
                          icon={billing.icon}
                          item={billing}
                          toNavigate={navigate}
                        />
                      )}
                      <InternalNavItem
                        icon={projects.icon}
                        item={projects}
                        toNavigate={navigate}
                      />
                    </SignedIn>

                    <NavItem icon={<GiBookCover />} onClick={navigateToDocs}>
                      Documentation
                    </NavItem>
                    <NavItem icon={<SwitchIcon />} onClick={handleColorMode}>
                      {colorMode === 'light' ? 'Light Mode' : 'Dark Mode'}
                    </NavItem>
                    {ENABLE_ANALYTICS === true ? (
                      <LogoutNavWithAnalytics />
                    ) : (
                      <LogoutNav />
                    )}
                  </NavGroup>
                </SidebarSection>
                <SidebarOverlay zIndex="1" />
              </Sidebar>
            )}
          </>
        }
      >
        {children}
      </AppShell>
    </>
  );
}
