import type { LabSession, NewSessionState, Plan, Query } from './types';
import { IoRocket, IoTerminal } from 'react-icons/io5';
import { FaDatabase } from 'react-icons/fa';
import { PiExamFill } from 'react-icons/pi';
import { AiFillExperiment } from 'react-icons/ai';
import { MdMonitorHeart, MdRateReview } from 'react-icons/md';

export const PROMPT_TEMPLATE_PLACEHOLDER =
  'Enter a prompt here. \n' +
  'Use double curly braces for prompt template variables. i.e {{prompt_template_variable}}.';

export const DEFAULT_SCHEMA_VALUE = `{
    "name": "default_placeholder_get_current_weather",
    "description": "THIS IS A PLACEHOLDER: Get the current weather in a given location",
    "parameters": {
        "type": "object",
        "properties": {
            "location": {
                "type": "string",
                "description": "The city and state, e.g. San Francisco, CA"
            },
            "unit": {
                "type": "string", 
                "enum": ["celsius", "fahrenheit"]
            }
        },
        "required": ["location"]
    }
}`;

export const defaultPIDEInputsCell = {
  keys: ['query', 'additional_description'],
  values: [
    'Whether sunshine is good for you.',
    'Provide a concise, few sentence argument on why sunshine is good for you.',
  ],
};

export const DEFAULT_PIDE_FUNCTION = `import openai
import os
from datetime import datetime
from typing import List

# OpenAI keys are automatically set using the key provided on the settings page
def call_openai(
    messages: List[dict], model: str = "gpt-3.5-turbo-1106", temperature: float = 0.0
) -> str:
    response = openai.ChatCompletion.create(model=model, messages=messages, temperature=temperature)
    return response.choices[0].message.content


def argument_generator(query: str, additional_description: str = "") -> str:
    return call_openai(
        messages=[
            {
                "role": "system",
                "content": f"You are a debater making an argument on a topic."
                f"{additional_description}"
                f" The current time is {datetime.now()}",
            },
            {"role": "user", "content": f"The discussion topic is {query}"},
        ]
    )


def critic(argument: str) -> str:
    return call_openai(
        messages=[
            {
                "role": "system",
                "content": f"You are a critic."
                "\\nWhat unresolved questions or criticism do you have after reading the following argument?"
                "Provide a concise summary of your feedback.",
            },
            {"role": "system", "content": argument},
        ]
    )


def refiner(
    query: str, additional_description: str, current_arg: str, criticism: str
) -> str:
    return call_openai(
        messages=[
            {
                "role": "system",
                "content": f"You are a debater making an argument on a topic."
                f"{additional_description}"
                f" The current time is {datetime.now()}",
            },
            {"role": "user", "content": f"The discussion topic is {query}"},
            {"role": "assistant", "content": current_arg},
            {"role": "user", "content": criticism},
            {
                "role": "system",
                "content": "Please generate a new argument that incorporates the feedback from the user.",
            },
        ]
    )


def argument_chain(query: str, additional_description: str = "") -> str:
    argument = argument_generator(query, additional_description)
    criticism = critic(argument)
    return refiner(query, additional_description, argument, criticism)


# Do not delete. This is the function that will be called by the PIDE
def fun(query: str, additional_description: str) -> str:
    result = argument_chain(query, additional_description)
    return f"The refined argument for your query is: {result}"
`;

// BACKEND ROUTES
export const LAB_COMPARE_ENDPOINT = '/api/lab/compare';
export const LAB_SAVE_ENDPOINT = '/api/lab/save';
export const LAB_SESSION_ENDPOINT = '/api/lab/session';
export const LAB_GET_SESSIONS_ENDPOINT = '/api/lab/sessions';
export const LAB_UPDATE_SESSION_NAME_ENDPOINT = '/api/lab/session/update_name';
export const LAB_UPDATE_EVAL_METRIC_ID_ENDPOINT =
  '/api/lab/session/update_evaluation_metric';
export const LAB_GET_SESSION_RESULTS = '/api/lab/results';
export const LAB_EVALUATE_ENDPOINT = '/api/lab/evaluate';
export const LAB_EVALUATE_SINGLE_ENDPOINT = '/api/lab/evaluate/single';
export const LAB_SAVE_NEW_VERSION_ENDPOINT = '/api/lab/save_new_version';
export const LAB_FEEDBACK_ENDPOINT = '/api/lab/collect_feedback';
export const LAB_INFERENCE_STREAMING_ENDPOINT = '/api/lab/inference_streaming';
export const LAB_STREAM_COMPLETION_ENDPOINT = '/api/lab/stream_completion';
export const LAB_SAVE_IMAGE = '/api/lab/save_image';
export const STUDIO_ENDPOINT_BASE = '/api/studio';
export const STUDIO_ENDPOINT = `${STUDIO_ENDPOINT_BASE}/`;
export const STUDIO_VERSION_UP_ENDPOINT = '/api/studio/version-up';
export const getStudioByIdEndpoint = (versionId, fetchResultDetails = false) =>
  `${STUDIO_ENDPOINT_BASE}/${versionId}?fetch_result_details=${fetchResultDetails}`;
export const GET_SHARED_RESULTS_ENDPOINT = '/api/studio/shared/';
export const STUDIO_VERSION_UPDATE_ENDPOINT = '/api/studio/update';
export const DEPLOYED_PROMPT_ENDPOINT = '/api/studio/deployed-prompt';
export const UPDATE_DEPLOYED_PROMPT_ENDPOINT =
  '/api/studio/update-deployed-prompt';
export const DEPLOYED_PROMPTS_ENDPOINT = '/api/studio/deployed-prompts';
export const STUDIO_CLONE_SHARED_ENDPOINT = '/api/studio/clone/';
export const STUDIO_SHARE_ENDPOINT = '/api/studio/share';
export const USER_CREDITS_ENDPOINT = '/api/user/credits';
export const USER_SUMMARY_ENDPOINT = '/api/user/summary';
export const PUBLIC_API_KEY_ENDPOINT = '/api/user/public-api-key';
export const CHECKOUT_SESSION_ENDPOINT = '/api/user/create-checkout-session';
export const CHECKOUT_PORTAL_ENDPOINT = '/api/user/create-portal-session';
export const USER_API_KEY_ENDPOINT = '/api/user/api_key';
export const USER_API_KEYS_ENDPOINT = '/api/user/api_keys';
export const USER_ORG_API_KEYS_ENDPOINT = '/api/user/org_api_keys';
export const USER_INFOS_ORG_ENDPOINT = '/api/user/user_infos_org';
export const TRACE_LOG_ENDPOINT_BASE = '/api/trace_log';
export const ANNOTATION_ENDPOINT_BASE = '/api/annotation';
export const ANALYTICS_ENDPOINT = '/api/analytics/';

export const TEST_CASES_ENDPOINT = '/api/test_cases';
export const TEST_CASES_GET_COLLECTIONS_ENDPOINT = `${TEST_CASES_ENDPOINT}/collections`;
export const TEST_CASES_CREATE_COLLECTION_ENDPOINT = `${TEST_CASES_ENDPOINT}/collection`;
export const TEST_CASES_BULK_INSERT_ENDPOINT = `${TEST_CASES_ENDPOINT}/cases`;
export const getTestCaseCollectionByIdEndpoint = (collectionId) =>
  `${TEST_CASES_ENDPOINT}/collection/${collectionId}`;
export const getTestCollectionUpdateColumnNamesEndpoint = (collectionId) =>
  `${TEST_CASES_ENDPOINT}/collection/${collectionId}/update_column_names`;
export const getTestCollectionAddColumnsEndpoint = (collectionId) =>
  `${TEST_CASES_ENDPOINT}/collection/${collectionId}/add_columns`;
export const getTestCaseByIdEndpoint = (testCaseId) =>
  `${TEST_CASES_ENDPOINT}/case/${testCaseId}`;
export const UPDATE_TEST_CASE_TAGS_ENDPOINT = `${TEST_CASES_ENDPOINT}/case/update_tags`;

export const UPDATE_TEST_CASE_ENDPOINT = `${TEST_CASES_ENDPOINT}/update_test_case`;

export const DATASET_REFERENCE_ENDPOINT = `${TEST_CASES_ENDPOINT}/dataset_reference`;
export const GET_DATASET_REFERENCES_ENDPOINT = `${TEST_CASES_ENDPOINT}/dataset_references`;

export const getDeployedPromptLogsEndpoint = (organization_id) =>
  `${STUDIO_ENDPOINT_BASE}/deployed-prompt/org/${organization_id}/logs`;
export const getDeployedPromptLogsByIdEndpoint = (deploymentId) =>
  `${STUDIO_ENDPOINT_BASE}/deployed-prompt/${deploymentId}/logs`;
export const LOGS_BY_ID_ENDPOINT = `${STUDIO_ENDPOINT_BASE}/logs`;
export const getTraceLogsEndpoint = (organization_id) =>
  `${STUDIO_ENDPOINT_BASE}/trace_logs_tree/${organization_id}`;
export const getTraceLogEndpoint = (trace_id) =>
  `${TRACE_LOG_ENDPOINT_BASE}/trace_log_tree/${trace_id}`;
export const ADD_COMMENT_TO_TRACE_LOG_ENDPOINT = `${ANNOTATION_ENDPOINT_BASE}/comment`;
export const hasAnyTraceLogsEndpoint = (org_id) =>
  `${TRACE_LOG_ENDPOINT_BASE}/has_any_trace_logs/${org_id}`;
export const ADD_LAB_SESSION_COMMENT_ENDPOINT = '/api/lab/lab_session_comment';
export const GET_LAB_SESSION_COMMENTS_ENDPOINT =
  '/api/lab/get_lab_session_comments';

export const getDeleteLabSessionCommentEndpoint = (
  organization_id,
  lab_session_id,
  comment_id
) =>
  `${ADD_LAB_SESSION_COMMENT_ENDPOINT}/${organization_id}/${lab_session_id}/comment/${comment_id}`;

export const getDeleteCommentFromTraceLogEndpoint = (trace_id, comment_id) =>
  `${ANNOTATION_ENDPOINT_BASE}/${trace_id}/comment/${comment_id}`;
export const DEFINED_ANNOTATIONS_ENDPOINT = `${ANNOTATION_ENDPOINT_BASE}/defined_annotations`;
export const CREATE_DEFINED_ANNOTATION_ENDPOINT = `${ANNOTATION_ENDPOINT_BASE}/defined_annotation`;
export const ANNOTATE_TRACE_LOG_ENDPOINT = `${ANNOTATION_ENDPOINT_BASE}/annotate`;
export const UPLOAD_ANNOTATIONS_ENDPOINT = `${ANNOTATION_ENDPOINT_BASE}/upload_annotations`;
export const getAnnotationCriterionCountEndpoint = (criterion_id) =>
  `${ANNOTATION_ENDPOINT_BASE}/annotation_criterion_count/${criterion_id}`;
export const BOOTSTRAP_EVAL_FROM_ANNOTATION_CRITERION_ENDPOINT = `${ANNOTATION_ENDPOINT_BASE}/bootstrap_eval`;
export const getBootstrappedEvalFullResultsEndpoint = (bootstrappedEvalId) =>
  `${ANNOTATION_ENDPOINT_BASE}/bootstrap_eval/${bootstrappedEvalId}`;

export const CREATE_ANNOTATION_QUEUE_ENDPOINT = `${ANNOTATION_ENDPOINT_BASE}/annotation_queue`;
export const GET_ANNOTATION_QUEUES = `${ANNOTATION_ENDPOINT_BASE}/annotation_queues`;
export const getAnnotationQueueByIdEndpoint = (queueUuid) =>
  `${ANNOTATION_ENDPOINT_BASE}/annotation_queue/${queueUuid}`;
export const ADD_ITEMS_TO_ANNOTATIONS_QUEUE = `${ANNOTATION_ENDPOINT_BASE}/annotation_queue_items`;
export const ADD_EXPERIMENT_LOGS_TO_ANNOTATIONS_QUEUE = `${ANNOTATION_ENDPOINT_BASE}/annotation_queue_items_experiment`;

export const finishItemFromAnnotationQueueByEndpoint = (itemUuid) =>
  `${ANNOTATION_ENDPOINT_BASE}/annotation_queue_item/${itemUuid}`;

export const undoFinishItemFromAnnotationQueueByEndpoint = (itemUuid) =>
  `${ANNOTATION_ENDPOINT_BASE}/annotation_queue_item/${itemUuid}/undo_finish`;

export const deleteAnnotationQueueByUuidEndpoint = (queueUuid) =>
  `${ANNOTATION_ENDPOINT_BASE}/annotation_queue/${queueUuid}`;
export const clearAnnotationQueueByUuidEndpoint = (queueUuid) =>
  `${getAnnotationQueueByIdEndpoint(queueUuid)}/clear`;

export const GET_FUNCTIONS_ENDPOINT = `${STUDIO_ENDPOINT_BASE}/functions/all`;
export const GET_FUNCTIONS_LAB_ENDPOINT = `${STUDIO_ENDPOINT_BASE}/functions/lab`;
export const FUNCTION_ENDPOINT = `${STUDIO_ENDPOINT_BASE}/function`;
export const getFunctionByIdEndpoint = (functionId) =>
  `${FUNCTION_ENDPOINT}/${functionId}`;
export const FUNCTION_VERSION_UP_ENDPOINT = `${FUNCTION_ENDPOINT}/version-up`;

export const EVAL_METRICS_GET_ALL_ENDPOINT = '/api/test_cases/eval_metrics';
export const EVAL_METRIC_ENDPOINT = '/api/test_cases/eval_metric';
export const getEvalMetricByIdEndpoint = (metricId) =>
  `/api/test_cases/eval_metric/${metricId}`;
export const DEBUG_EVAL_METRIC_ENDPOINT = '/api/test_cases/debug_eval_metric';
export const EVAL_JOB_ENDPOINT = '/api/test_cases/evaluation_job';
export const PIDE_ENDPOINT_BASE = '/api/pide';
export const PIDE_ENDPOINT = `${PIDE_ENDPOINT_BASE}/`;
export const PIDE_RUN_ENDPOINT = `${PIDE_ENDPOINT_BASE}/run`;
export const PIDE_CREATE_ENDPOINT = `${PIDE_ENDPOINT_BASE}/create`;
export const PIDE_SAVE_ENDPOINT = `${PIDE_ENDPOINT_BASE}/save`;
export const getPideByIdEndpoint = (pideId) =>
  `${PIDE_ENDPOINT_BASE}/${pideId}`;
export const getPideUpdateNameByIdEndpoint = (pideId) =>
  `${getPideByIdEndpoint(pideId)}/update_name`;
export const EXPERIMENT_ENDPOINT_BASE = '/api/experiment';
export const getAllExperimentsEndpoint = (projectUuid) =>
  `${EXPERIMENT_ENDPOINT_BASE}/all/${projectUuid}`;
export const getExperimentEndpoint = (projectUuid) =>
  `${EXPERIMENT_ENDPOINT_BASE}/${projectUuid}`;

export const EXPERIMENTS_BULK_DELETE_ENDPOINT = `${EXPERIMENT_ENDPOINT_BASE}/bulk_delete`;
export const getExperimentStatsEndpoint = (experimentId) =>
  `${EXPERIMENT_ENDPOINT_BASE}/${experimentId}/stats`;
export const getExperimentDownloadEndpoint = (experimentId) =>
  `${EXPERIMENT_ENDPOINT_BASE}/${experimentId}/download`;
export const getExperimentStatEndpoint = (experimentId) =>
  `${EXPERIMENT_ENDPOINT_BASE}/${experimentId}/stat`;
export const getExperimentShareEndpoint = (experimentId) =>
  `${EXPERIMENT_ENDPOINT_BASE}/${experimentId}/toggle-visibility`;
export const EXPERIMENTS_COMPARISON_ENDPOINT = `${EXPERIMENT_ENDPOINT_BASE}/compare_experiments`;

export const EXPERIMENTS_SET_COMPARISON_URL_ENDPOINT = `${EXPERIMENT_ENDPOINT_BASE}/comparison/set`;

export const getRecentComparisonEndpoint = (projectName) =>
  `${EXPERIMENT_ENDPOINT_BASE}/comparison/${projectName}`;

export const EXPERIMENT_UPDATE_ENDPOINT = `${EXPERIMENT_ENDPOINT_BASE}/update`;
export const DASHBOARD_STATS_ENDPOINT = '/api/dashboard_stats/chart-data';
export const getProjectsEndpoint = (organization_id) =>
  `/api/user/projects/${organization_id}`;
export const updateProjectEndpoint = (organization_id, projectUuid) =>
  `${getProjectsEndpoint(organization_id)}/${projectUuid}`;
export const PUBLIC_EXPERIMENT_ENDPOINT_BASE = '/api/public-experiment';
export const getPublicExperimentStatsEndpoint = (experimentId) =>
  `${PUBLIC_EXPERIMENT_ENDPOINT_BASE}/${experimentId}/stats`;
export const getAllPublicExperimentsEndpoint = (orgId, projectName) =>
  `${PUBLIC_EXPERIMENT_ENDPOINT_BASE}/all/${orgId}/${projectName}`;

export const UPDATE_TRACE_EVAL_SCORES_ENDPOINT = `${TRACE_LOG_ENDPOINT_BASE}/trace_log_score`;

export const GET_UNIQUE_VALUES_ENDPOINT = `${TRACE_LOG_ENDPOINT_BASE}/unique_values`;

// Environment variables
export const HOMEPAGE = 'https://www.parea.ai';
export const CLERK_PUB_KEY = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;
export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
export const AUTH_SERVICE_URL = process.env.REACT_APP_AUTH_SERVICE_URL || '';
export const POSTHOG_KEY = process.env.REACT_APP_POSTHOG_KEY;
export const POSTHOG_HOST = process.env.REACT_APP_POSTHOG_HOST;
export const JUNE_KEY = process.env.REACT_APP_JUNE_KEY;
export const DEV_ENV = process.env.NODE_ENV === 'development';
export const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;
export const ENABLE_ANALYTICS =
  process.env.REACT_APP_ENABLE_ANALYTICS === 'true';
export const ENABLE_CLERK_AUTHENTICATION =
  process.env.REACT_APP_ENABLE_CLERK_AUTHENTICATION === 'true';
export const SENTRY_DSN = process.env.SENTRY_DSN;
export const HIDE_BILLING = ENABLE_ANALYTICS === false;
export const HIDE_PLAYGROUND_EVALS = ENABLE_ANALYTICS === false;
export const SELF_HOSTED_ORGANIZATION_ID =
  process.env.REACT_APP_SELF_HOST_ORGANIZATION_ID;
export const SELF_HOSTED_ORGANIZATION_NAME =
  process.env.REACT_APP_SELF_HOST_ORGANIZATION_NAME;
export const HIDE_BOOTSTRAPPING_EVALS = ENABLE_ANALYTICS === false;

// Validation constants
export const MAX_EXAMPLE_VALUE = 2000;
export const MAX_TOTAL = 5000;
export const SURCHARGE_TRIGGER = 3000;

export const ENTERPRISE_MEETING_ID = 'https://calendly.com/parea-ai/enterprise';
export const ONBOARDING_MEETING_ID = 'https://calendly.com/parea-ai/chat';

export const MISSING_API_KEY =
  'Provide the correct API key(s) for the selected model under Settings, or change to a valid model';

// Models
export const OPENAI_MODELS = [
  'gpt-4o',
  'gpt-4o-mini',
  'gpt-4-0125-preview',
  'gpt-4o-2024-08-06',
  'gpt-4o-2024-05-13',
  'gpt-4',
  'chatgpt-4o-latest',
  'gpt-4-turbo',
  'gpt-3.5-turbo',
  'gpt-4o-mini-2024-07-18',
  'o1-preview',
  'o1-preview-2024-09-12',
  'o1-mini',
  'o1-mini-2024-09-12',
  'gpt-4-1106-preview',
  'gpt-4-turbo-preview',
  'gpt-3.5-turbo-0125',
  'gpt-3.5-turbo-1106',
  'gpt-3.5-turbo-16k',
  'gpt-4-0613',
  'gpt-4-32k-0613',
];

export const ANTHROPIC_MODELS = [
  'claude-3-5-sonnet-20241022',
  'claude-3-5-haiku-20241022',
  'claude-3-5-sonnet-latest',
  'claude-3-opus-latest',
  'claude-3-5-sonnet-20240620',
  'claude-3-sonnet-20240229',
  'claude-3-haiku-20240307',
  'claude-3-opus-20240229',
  'claude-2.1',
  'claude-instant-1.2',
];

export const ANYSCALE_MODELS = [
  'google/gemma-7b-it',
  'meta-llama/Meta-Llama-3-8B-Instruct',
  'meta-llama/Meta-Llama-3-70B-Instruct',
  'meta-llama/Llama-2-70b-chat-hf',
  'meta-llama/Llama-2-13b-chat-hf',
  'meta-llama/Llama-2-7b-chat-hf',
  'codellama/CodeLlama-34b-Instruct-hf',
  'mistralai/Mistral-7B-Instruct-v0.1',
  'mistralai/Mixtral-8x7B-Instruct-v0.1',
  'HuggingFaceH4/zephyr-7b-beta',
  'Open-Orca/Mistral-7B-OpenOrca',
];

export const AWS_BEDROCK_MODELS = [
  'anthropic.claude-3-5-sonnet-20240620-v1:0',
  'anthropic.claude-3-haiku-20240307-v1:0',
  'anthropic.claude-3-sonnet-20240229-v1:0',
  'anthropic.claude-3-opus-20240229-v1:0',
  'anthropic.claude-v2:1',
  'anthropic.claude-v2',
  'anthropic.claude-instant-v1',
  'meta.llama3-8b-instruct-v1:0',
  'meta.llama3-70b-instruct-v1:0',
  'meta.llama2-70b-chat-v1',
  'meta.llama2-13b-chat-v1',
  'mistral.mistral-7b-instruct-v0:2',
  'mistral.mixtral-8x7b-instruct-v0:1',
  'mistral.mistral-large-2402-v1:0',
  'mistral.mistral-large-2407-v1:0',
  // 'cohere.command-text-v14',
  // 'cohere.command-light-text-v14',
  // 'cohere.command-r-plus-v1:0',
  // 'cohere.command-r-v1:0',
];

export const VERTEXAI_MODELS = [
  'gemini-pro',
  'text-bison@002',
  'text-bison@001',
  'text-bison',
  'text-bison-32k',
  'chat-bison@002',
  'chat-bison@001',
  'chat-bison',
  'chat-bison-32k',
  'gemini-1.5-flash',
  'gemini-1.5-pro',
];

export const MISTRAL_MODELS = [
  'mistral-large-2407',
  'open-mistral-7b',
  'open-mixtral-8x7b',
  'open-mixtral-8x22b',
  'mistral-small-latest',
  'mistral-medium-latest',
  'mistral-large-latest',
  'codestral-latest',
  'codestral-mamba-latest',
  'open-mistral-nemo-2407',
];

export const GROQ_MODELS = [
  'llama3-8b-8192',
  'llama3-70b-8192',
  'llama3-groq-8b-8192-tool-use-preview',
  'llama3-groq-70b-8192-tool-use-preview',
  'mixtral-8x7b-32768',
  'gemma-7b-it',
  'gemma2-9b-it',
];

export const FIREWORKS_MODELS = [
  'llama-v3p1-8b-instruct',
  'llama-v3p1-70b-instruct',
  'llama-v3p1-405b-instruct',
];

export const OPENAI_MODELS_WITH_RESPONSE_FORMAT = [
  'gpt-3.5-turbo',
  'gpt-3.5-turbo-1106',
  'gpt-3.5-turbo-0125',
  'gpt-4-turbo',
  'gpt-4-turbo-preview',
  'gpt-4-1106-preview',
  'gpt-4-0125-preview',
  'gpt-4o',
  'gpt-4o-2024-05-13',
  'gpt-4o-2024-08-06',
  'gpt-4o-mini',
  'gpt-4o-mini-2024-07-18',
  'open-mistral-7b',
  'open-mixtral-8x7b',
  'open-mixtral-8x22b',
  'mistral-small-latest',
  'mistral-medium-latest',
  'mistral-large-latest',
  'mistral-large-2407',
  'open-mistral-nemo-2407',
  'chatgpt-4o-latest',
  ...GROQ_MODELS,
];

export const SUPPORTS_FUNCTIONS = new Set([
  'gpt-3.5-turbo',
  'gpt-3.5-turbo-0613',
  'gpt-3.5-turbo-0301',
  'gpt-3.5-turbo-16k',
  'gpt-3.5-turbo-16k-0613',
  'gpt-3.5-turbo-16k-0301',
  'gpt-4',
  'gpt-4-0613',
  'gpt-4-0314',
  'gpt-4-32k',
  'gpt-4-32k-0613',
  'gpt-4-32k-0314',
  'gpt-4-turbo',
  'gpt-4-turbo-preview',
  'gpt-4-1106-preview',
  'gpt-4-0125-preview',
  'gpt-3.5-turbo-1106',
  'gpt-3.5-turbo-0125',
  'gpt-4o',
  'gpt-4o-2024-05-13',
  'gpt-4o-2024-08-06',
  'gpt-4o-mini',
  'gpt-4o-mini-2024-07-18',
  'chatgpt-4o-latest',
  'mistralai/Mistral-7B-Instruct-v0.1',
  'mistral-small-latest',
  'mistral-large-latest',
  'open-mixtral-8x22b',
  'mistral-large-2407',
  ...GROQ_MODELS,
]);

export const SUPPORTS_VISION = new Set([
  'gpt-4o',
  'gpt-4o-2024-05-13',
  'gpt-4o-mini',
  'gpt-4o-mini-2024-07-18',
  'gpt-4o-2024-08-06',
  'gpt-4-vision-preview',
  'claude-3-5-sonnet-20240620',
  'claude-3-opus-20240229',
  'claude-3-sonnet-20240229',
  'claude-3-haiku-20240307',
  'claude-3-5-sonnet-20241022',
  'claude-3-5-sonnet-latest',
  'claude-3-opus-latest',
]);

export const OPENROUTER_MODELS = [
  'openrouter/auto',
  'openrouter/cinematika-7b:free',
  'openrouter/cinematika-7b',
  'openai/gpt-4o-mini-2024-07-18',
  'openai/gpt-4o-mini',
  'anthropic/claude-3.5-sonnet:beta',
  'anthropic/claude-2',
  'meta-llama/llama-3.1-70b-instruct',
  'meta-llama/llama-3.1-8b-instruct:free',
  'meta-llama/llama-3.1-8b-instruct',
  'meta-llama/llama-3.1-405b-instruct',
  'meta-llama/llama-guard-2-8b',
  'meta-llama/llama-3-70b-instruct',
  'meta-llama/llama-3-8b-instruct',
  'meta-llama/llama-2-13b-chat',
  'databricks/dbrx-instruct',
  'databricks/dbrx-instruct:nitro',
  'mistralai/mistral-7b-instruct-v0.3',
  'mistralai/codestral-mamba',
  'mistralai/mistral-nemo',
  'mistralai/mistral-7b-instruct:nitro',
  'qwen/qwen-2-7b-instruct',
  'qwen/qwen-2-7b-instruct:free',
  'google/gemini-flash-1.5',
  'google/gemma-2-27b-it',
  'google/gemma-2-9b-it:free',
  'google/gemma-2-9b-it',
  'google/gemma-7b-it:free',
  'google/gemma-7b-it',
  'microsoft/phi-3-medium-4k-instruct',
  'microsoft/wizardlm-2-8x22b',
  'microsoft/wizardlm-2-7b',
  'gryphe/mythomax-l2-13b',
  'gryphe/mythomax-l2-13b',
  'gryphe/mythomist-7b:free',
  'gryphe/mythomax-l2-13b',
  'gryphe/mythomax-l2-13b-8k',
  'gryphe/mythomist-7b',
  'undi95/remm-slerp-l2-13b',
  'undi95/toppy-m-7b',
  '01-ai/yi-34b-chat',
  '01-ai/yi-34b',
  '01-ai/yi-6b',
  '01-ai/yi-large',
  'togethercomputer/stripedhyena-nous-7b',
  'togethercomputer/stripedhyena-hessian-7b',
  'nousresearch/nous-hermes-yi-34b',
  'nousresearch/nous-hermes-2-mistral-7b-dpo',
  'nousresearch/nous-capybara-34b',
  'perplexity/llama-3-sonar-large-32k-online',
  'perplexity/llama-3-sonar-large-32k-chat',
  'perplexity/llama-3-sonar-small-32k-chat',
  'perplexity/pplx-70b-online',
  'perplexity/pplx-7b-online',
  'perplexity/pplx-7b-chat',
  'perplexity/pplx-70b-chat',
  'perplexity/sonar-small-chat',
  'perplexity/sonar-medium-chat',
  'perplexity/sonar-small-online',
  'perplexity/sonar-medium-online',
  'openchat/openchat-7b',
  'openchat/openchat-7b:free',
  'cognitivecomputations/dolphin-llama-3-70b',
  'cognitivecomputations/dolphin-mixtral-8x7b',
  'recursal/rwkv-5-3b-ai-town',
  'recursal/eagle-7b',
  'jondurbin/airoboros-l2-70b',
  'alpindale/magnum-72b',
  'mancer/weaver',
  'undi95/toppy-m-7b:free',
  'sao10k/l3-stheno-8b',
  'ai21/jamba-instruct',
  'snowflake/snowflake-arctic-instruct',
  'phind/phind-codellama-34b',
  'intel/neural-chat-7b',
  'haotian-liu/llava-13b',
  'migtissera/synthia-70b',
  'pygmalionai/mythalion-13b',
  'xwin-lm/xwin-lm-70b',
  'neversleep/noromaid-20b',
  'nousresearch/nous-capybara-7b',
  'austism/chronos-hermes-13b',
  'lizpreciatior/lzlv-70b-fp16-hf',
  'rwkv/rwkv-5-world-3b',
  'huggingfaceh4/zephyr-7b-beta:free',
];

export const COHERE_MODELS = [
  'command-r-plus',
  'command-r',
  'command',
  'command-nightly',
  'command-light',
  'command-light-nightly',
  'c4ai-aya-23',
  'rerank-english-v3.0',
  'rerank-multilingual-v3.0',
  'rerank-english-v2.0',
  'rerank-multilingual-v2.0',
];

export const MAX_TOKENS = {
  'gpt-3.5-turbo': { maxInputTokens: 4096, maxCompletionTokens: 4096 },
  'gpt-3.5-turbo-0613': { maxInputTokens: 4096, maxCompletionTokens: 4096 },
  'gpt-3.5-turbo-0301': { maxInputTokens: 4096, maxCompletionTokens: 4096 },
  'gpt-3.5-turbo-16k': {
    maxInputTokens: 16385,
    maxCompletionTokens: 16385,
  },
  'gpt-3.5-turbo-16k-0613': {
    maxInputTokens: 16385,
    maxCompletionTokens: 16385,
  },
  'gpt-3.5-turbo-16k-0301': {
    maxInputTokens: 16385,
    maxCompletionTokens: 16385,
  },
  'gpt-4': { maxInputTokens: 8192, maxCompletionTokens: 8192 },
  'gpt-4-0613': { maxInputTokens: 8192, maxCompletionTokens: 8192 },
  'gpt-4-0314': { maxInputTokens: 8192, maxCompletionTokens: 8192 },
  'gpt-4-32k': { maxInputTokens: 32768, maxCompletionTokens: 32768 },
  'gpt-4-32k-0613': { maxInputTokens: 32768, maxCompletionTokens: 32768 },
  'gpt-4-32k-0314': { maxInputTokens: 32768, maxCompletionTokens: 32768 },
  'gpt-4-1106-preview': {
    maxInputTokens: 128000,
    maxCompletionTokens: 4096,
  },
  'gpt-4-vision-preview': {
    maxInputTokens: 128000,
    maxCompletionTokens: 4096,
  },
  'gpt-3.5-turbo-1106': {
    maxInputTokens: 16385,
    maxCompletionTokens: 16385,
  },
  'gpt-4o-2024-05-13': {
    maxCompletionTokens: 4096,
    maxInputTokens: 128000,
  },
  'gpt-4o': {
    maxCompletionTokens: 4096,
    maxInputTokens: 128000,
  },
  'gpt-4o-mini': {
    maxCompletionTokens: 4096,
    maxInputTokens: 128000,
  },
  'gpt-4o-mini-2024-07-18': {
    maxCompletionTokens: 4096,
    maxInputTokens: 128000,
  },
  'gpt-4o-2024-08-06': {
    maxCompletionTokens: 4096,
    maxInputTokens: 128000,
  },
  'chatgpt-4o-latest': {
    maxCompletionTokens: 16384,
    maxInputTokens: 128000,
  },
  'o1-preview': {
    maxCompletionTokens: 16384,
    maxInputTokens: 128000,
  },
  'o1-preview-2024-09-12': {
    maxCompletionTokens: 16384,
    maxInputTokens: 128000,
  },
  'o1-mini': {
    maxCompletionTokens: 16384,
    maxInputTokens: 128000,
  },
  'o1-mini-2024-09-12': {
    maxCompletionTokens: 16384,
    maxInputTokens: 128000,
  },

  'claude-3-5-sonnet-20240620': {
    maxInputTokens: 200000,
    maxCompletionTokens: 4096,
  },
  'claude-3-opus-20240229': {
    maxInputTokens: 200000,
    maxCompletionTokens: 4096,
  },
  'claude-3-sonnet-20240229': {
    maxInputTokens: 200000,
    maxCompletionTokens: 4096,
  },
  'claude-3-haiku-20240307': {
    maxInputTokens: 200000,
    maxCompletionTokens: 4096,
  },
  'claude-2.1': { maxInputTokens: 200000, maxCompletionTokens: 4096 },
  'claude-2': { maxInputTokens: 100000, maxCompletionTokens: 4096 },
  'claude-instant-1.1': { maxInputTokens: 9000, maxCompletionTokens: 4096 },
  'claude-instant-1.2': { maxInputTokens: 100000, maxCompletionTokens: 4096 },
  'claude-instant-1': { maxInputTokens: 100000, maxCompletionTokens: 4096 },
  'claude-1': { maxInputTokens: 9000, maxCompletionTokens: 4096 },
  'claude-1-100k': { maxInputTokens: 100000, maxCompletionTokens: 4096 },
  'claude-1.2': { maxInputTokens: 9000, maxCompletionTokens: 4096 },
  'claude-1.3': { maxInputTokens: 9000, maxCompletionTokens: 4096 },
  'claude-instant-1-100k': {
    maxInputTokens: 100000,
    maxCompletionTokens: 4096,
  },
  'claude-instant-1.1-100k': {
    maxInputTokens: 100000,
    maxCompletionTokens: 4096,
  },
  'claude-1.3-100k': { maxInputTokens: 100000, maxCompletionTokens: 4096 },

  'claude-3-5-sonnet-20241022': {
    maxInputTokens: 200000,
    maxCompletionTokens: 8192,
  },
  'claude-3-5-sonnet-latest': {
    max_prompt_tokens: 200000,
    maxCompletionTokens: 8192,
  },
  'claude-3-opus-latest': {
    maxInputTokens: 200000,
    maxCompletionTokens: 4096,
  },
  'claude-3-5-haiku-20241022': {
    maxInputTokens: 200000,
    maxCompletionTokens: 8192,
  },

  'anthropic.claude-instant-v1': {
    maxInputTokens: 100000,
    maxCompletionTokens: 4096,
  },
  'anthropic.claude-2': { maxInputTokens: 100000, maxCompletionTokens: 4096 },
  'anthropic.claude-v2:1': {
    maxInputTokens: 200000,
    maxCompletionTokens: 4096,
  },
  'anthropic.claude-1': { maxInputTokens: 9000, maxCompletionTokens: 4096 },
  'anthropic.claude-3-5-sonnet-20240620-v1:0': {
    maxInputTokens: 200000,
    maxCompletionTokens: 4096,
  },
  'anthropic.claude-3-opus-20240229-v1:0': {
    maxInputTokens: 200000,
    maxCompletionTokens: 4096,
  },
  'anthropic.claude-3-sonnet-20240229-v1:0': {
    maxInputTokens: 200000,
    maxCompletionTokens: 4096,
  },
  'anthropic.claude-3-haiku-20240307-v1:0': {
    maxInputTokens: 200000,
    maxCompletionTokens: 4096,
  },
  'meta.llama3-8b-instruct-v1:0': {
    maxInputTokens: 8000,
    maxCompletionTokens: 2048,
  },
  'meta.llama3-70b-instruct-v1:0': {
    maxInputTokens: 8000,
    maxCompletionTokens: 2048,
  },
  'meta.llama2-70b-chat-v1': {
    maxInputTokens: 4096,
    maxCompletionTokens: 2048,
  },
  'meta.llama2-13b-chat-v1': {
    maxInputTokens: 4096,
    maxCompletionTokens: 2048,
  },
  'mistral.mistral-7b-instruct-v0:2': {
    maxInputTokens: 32000,
    maxCompletionTokens: 8192,
  },
  'mistral.mixtral-8x7b-instruct-v0:1': {
    maxInputTokens: 32000,
    maxCompletionTokens: 4096,
  },
  'mistral.mistral-large-2402-v1:0': {
    maxInputTokens: 32000,
    maxCompletionTokens: 8192,
  },
  // 'cohere.command-text-v14': {
  //   maxInputTokens: 4000,
  //   maxCompletionTokens: 4000,
  // },
  // 'cohere.command-light-text-v14': {
  //   maxInputTokens: 4000,
  //   maxCompletionTokens: 4000,
  // },
  // 'cohere.command-r-plus-v1:0': {
  //   maxInputTokens: 128000,
  //   maxCompletionTokens: 128000,
  // },
  // 'cohere.command-r-v1:0': {
  //   maxInputTokens: 128000,
  //   maxCompletionTokens: 128000,
  // },

  'google/gemma-7b-it': {
    maxInputTokens: 8192,
    maxCompletionTokens: 8192,
  },
  'meta-llama/Meta-Llama-3-8B-Instruct': {
    maxInputTokens: 8192,
    maxCompletionTokens: 8192,
  },
  'meta-llama/Meta-Llama-3-70B-Instruct': {
    maxInputTokens: 8192,
    maxCompletionTokens: 8192,
  },
  'meta-llama/Llama-2-70b-chat-hf': {
    maxInputTokens: 4096,
    maxCompletionTokens: 4096,
  },
  'meta-llama/Llama-2-13b-chat-hf': {
    maxInputTokens: 4096,
    maxCompletionTokens: 4096,
  },
  'meta-llama/Llama-2-7b-chat-hf': {
    maxInputTokens: 4096,
    maxCompletionTokens: 4096,
  },
  'codellama/CodeLlama-34b-Instruct-hf': {
    maxInputTokens: 4096,
    maxCompletionTokens: 4096,
  },
  'mistralai/Mistral-7B-Instruct-v0.1': {
    maxInputTokens: 4096,
    maxCompletionTokens: 4096,
  },
  'mistralai/Mixtral-8x7B-Instruct-v0.1': {
    maxInputTokens: 32768,
    maxCompletionTokens: 32768,
  },
  'HuggingFaceH4/zephyr-7b-beta': {
    maxInputTokens: 16384,
    maxCompletionTokens: 16384,
  },
  'Open-Orca/Mistral-7B-OpenOrca': {
    maxInputTokens: 8912,
    maxCompletionTokens: 8912,
  },

  'text-bison@001': {
    maxInputTokens: 8192,
    maxCompletionTokens: 1024,
  },
  'text-bison': {
    maxInputTokens: 8192,
    maxCompletionTokens: 2048,
  },
  'text-bison-32k': {
    maxInputTokens: 32000,
    maxCompletionTokens: 32000,
  },
  'chat-bison@001': {
    maxInputTokens: 8192,
    maxCompletionTokens: 1024,
  },
  'chat-bison': {
    maxInputTokens: 8192,
    maxCompletionTokens: 2048,
  },
  'chat-bison-32k': {
    maxInputTokens: 32000,
    maxCompletionTokens: 32000,
  },
  'open-mistral-7b': {
    maxInputTokens: 16384,
    maxCompletionTokens: 16384,
  },
  'gemini-1.5-pro': {
    maxInputTokens: 2097152,
    maxCompletionTokens: 8192,
  },
  'gemini-1.5-flash': {
    maxInputTokens: 1048576,
    maxCompletionTokens: 8192,
  },

  'mistral-small-latest': {
    maxInputTokens: 32768,
    maxCompletionTokens: 32768,
  },
  'mistral-medium-latest': {
    maxInputTokens: 32768,
    maxCompletionTokens: 32768,
  },
  'open-mixtral-8x7b': {
    maxInputTokens: 32768,
    maxCompletionTokens: 32768,
  },
  'open-mixtral-8x22b': {
    maxInputTokens: 64000,
    maxCompletionTokens: 64000,
  },
  'mistral-large-latest': {
    maxInputTokens: 32768,
    maxCompletionTokens: 32768,
  },
  'codestral-latest': {
    maxInputTokens: 32768,
    maxCompletionTokens: 32768,
  },
  'codestral-mamba-latest': {
    maxInputTokens: 256000,
    maxCompletionTokens: 256000,
  },
  'open-mistral-nemo-2407': {
    maxInputTokens: 128000,
    maxCompletionTokens: 128000,
  },
  'llama3-8b-8192': { maxCompletionTokens: 8192, maxInputTokens: 8192 },
  'llama3-70b-8192': { maxCompletionTokens: 8192, maxInputTokens: 8192 },
  'llama3-groq-8b-8192-tool-use-preview': {
    maxCompletionTokens: 8192,
    maxInputTokens: 8192,
  },
  'llama3-groq-70b-8192-tool-use-preview': {
    maxCompletionTokens: 8192,
    maxInputTokens: 8192,
  },
  'mixtral-8x7b-32768': { maxCompletionTokens: 32768, maxInputTokens: 32768 },
  'gemma-7b-it': { maxCompletionTokens: 8192, maxInputTokens: 8192 },
  'gemma2-9b-it': { maxCompletionTokens: 8192, maxInputTokens: 8192 },
  'mistral-large-2407': { maxCompletionTokens: 128000, maxInputTokens: 128000 },
  'mistral.mistral-large-2407-v1:0': {
    maxCompletionTokens: 128000,
    maxInputTokens: 128000,
  },
};

export function getProviderFromModelType(model_type) {
  switch (true) {
    case OPENAI_MODELS.includes(model_type):
      return 'openai';
    case ANTHROPIC_MODELS.includes(model_type) || model_type === 'ClaudeSonnet':
      return 'anthropic';
    case model_type.startsWith('azure_'):
      return 'azure';
    case model_type.startsWith('openrouter_'):
      return 'openrouter';
    case model_type.startsWith('litellm_'):
      return 'litellm';
    case ANYSCALE_MODELS.includes(model_type):
      return 'anyscale';
    case AWS_BEDROCK_MODELS.includes(model_type):
      return 'aws_bedrock';
    case VERTEXAI_MODELS.includes(model_type) ||
      model_type.startsWith('google'):
      return 'vertexai';
    case MISTRAL_MODELS.includes(model_type):
      return 'mistral';
    case GROQ_MODELS.includes(model_type):
      return 'groq';
    case FIREWORKS_MODELS.includes(model_type):
      return 'fireworks';
    case COHERE_MODELS.includes(model_type):
      return 'cohere';
    default:
      throw new Error(`Unknown model type: ${model_type}`);
  }
}

export const EXAMPLE_RAG_CONTEXT =
  'Company: Nike. 2023 \n' +
  'FORM 10-K 35\n' +
  'OPERATING SEGMENTS\n' +
  "As discussed in Note 15 2014 Operating Segments and Related Information in the accompanying Notes to the Consolidated Financial Statements, our operating segments are evidence of the structure of the Company's internal organization. The NIKE Brand segments are defined by geographic regions for operations participating in NIKE Brand sales activity.\n" +
  'The breakdown of Revenues is as follows:\n' +
  '\\n\\n(Dollars in millions)\n' +
  '\\n\\nFISCAL 2023 FISCAL 2022\n' +
  '\\n\\n% CHANGE\\n\\n% CHANGE EXCLUDING CURRENCY (1) CHANGES FISCAL 2021\\n\\n% CHANGE\\n\\n\n' +
  'North America Europe, Middle East & Africa Greater China\\n\\n$\\n\\n21,608 $ 13,418 7,248\\n\\n18,353 12,479 7,547\\n\\n18 % 8 % -4 %\\n\\n18 % $ 21 % 4 %\\n\\n17,179 11,456 8,290\\n\\n7 % 9 % -9 %\\n\\nAsia Pacific & Latin America Global Brand Divisions\\n\\n(3)\\n\\n(2)\\n\\n6,431 58\\n\\n5,955 102\\n\\n8 % -43 %\\n\\n17 % -43 %\\n\\n5,343 25\\n\\n11 % 308 %\\n\\nTOTAL NIKE BRAND Converse\\n\\n$\\n\\n48,763 $ 2,427\\n\\n44,436 2,346\\n\\n10 % 3 %\\n\\n16 % $ 8 %\\n\\n42,293 2,205\\n\\n5 % 6 %\\n\\n(4)\\n\\nCorporate TOTAL NIKE, INC. REVENUES\\n\\n$\\n\\n27\\n\\n51,217 $\\n\\n(72) 46,710\\n\\n\u2014 10 %\\n\\n\u2014 16 % $\\n\\n40 44,538\\n\\n\u2014 5 %';

// DEFAULT VALUES
export const defaultVersionItem: Query = {
  query_id: 0,
  version_id: 0,
  version_number: 0,
  versions_list: [{ version_id: 0, version: 0.0 }],
  messages: {
    0: {
      role: 'user',
      content:
        "Use the following pieces of context from Nike's financial 10k filings " +
        'dataset to answer the question. Do not make up an answer if no context is provided to help answer it.\n' +
        '\n' +
        'Context:\n' +
        '---------\n' +
        '{{context}}\n' +
        '\n' +
        '---------\n' +
        'Question: {{question}}\n' +
        '---------\n' +
        '\n' +
        'Answer:',
    },
  },
  result_details: {
    details: [
      {
        example_inputs: {
          context: EXAMPLE_RAG_CONTEXT,
          question:
            'Which operating segment contributed least to total Nike brand revenue in fiscal 2023?',
        },
        original_details: {
          content: 'Click compare to get a inference result.',
          tokens: null,
          cost: null,
          latency: null,
          logprobs: null,
          perplexity: null,
        },
        optimized_details: {
          content: 'Click compare to get a inference result.',
          tokens: null,
          cost: null,
          latency: null,
          logprobs: null,
          perplexity: null,
        },
      },
    ],
  },
  name: null,
  result_params: {
    model: OPENAI_MODELS[0],
    temp: 0.0,
    top_p: 1,
    frequency_penalty: 0,
    presence_penalty: 0,
    max_length: null,
    response_format: null,
    safe_prompt: false,
  },
  type: 'template',
  rating: null,
  tags: [],
  favorite: false,
  score: null,
  lab_session_ids: [0],
  function_ids: new Set(),
  created_at: new Date().toUTCString(),
};

export const defaultVersionItemVision: Query = {
  query_id: 0,
  version_id: 0,
  version_number: 0,
  versions_list: [{ version_id: 0, version: 0.0 }],
  messages: {
    0: {
      role: 'user',
      content: 'What can you see here {{image}}',
    },
  },
  result_details: {
    details: [],
  },
  name: null,
  result_params: {
    model: 'gpt-4-vision-preview',
    temp: 0.5,
    top_p: 1,
    frequency_penalty: 0,
    presence_penalty: 0,
    max_length: null,
    response_format: null,
    safe_prompt: false,
  },
  type: 'template',
  rating: null,
  tags: [],
  favorite: false,
  score: null,
  lab_session_ids: [0],
  function_ids: new Set(),
  created_at: new Date().toUTCString(),
};

export const PREFIX_URL_IMAGES_GPT_4 = 'https://imagesuser.s3.amazonaws.com/';
export const IMAGE_NOT_FOUND_URL =
  'https://via.placeholder.com/250?text=Image+not+found+or+could+not+be+loaded.';

export const defaultSessionItem: LabSession = {
  id: 0,
  name: 'New session name',
  evaluation_metric_ids: [],
  last_updated_at: new Date().toUTCString(),
  version_ids: [],
  result_ids: null,
};

export const defaultInputsCell1 = {
  keys: ['context', 'question'],
  values: [
    EXAMPLE_RAG_CONTEXT,
    'Which operating segment contributed least to total Nike brand revenue in fiscal 2023?',
  ],
};
export const defaultInputsCellVision = {
  keys: ['image'],
  values: [
    'https://imagesuser.s3.amazonaws.com/user_2P1FpGmZNlrie4tiWFGd5MrJZ7Q/4aec96ff-6735-41bb-a0de-8b599d7c6d78',
  ],
};

export const DEFAULT_INFERENCE_CONTENT =
  'Click compare to get a inference result.';
export const defaultInferenceCell = {
  content: DEFAULT_INFERENCE_CONTENT,
  latency: null,
  loading: false,
  resultId: null,
};

export const newLabSessionDefaults: NewSessionState = {
  session: defaultSessionItem,
  versionItems: {
    0: {
      ...defaultVersionItem,
      selected_session_id: defaultSessionItem.id,
    },
  },
  inputsCells: { 0: { ...defaultInputsCell1 } },
  inferenceCells: {
    0: { 0: { ...defaultInferenceCell } },
  },
};

export const newLabSessionDefaultsVision: NewSessionState = {
  session: defaultSessionItem,
  versionItems: {
    0: {
      ...defaultVersionItemVision,
      selected_session_id: defaultSessionItem.id,
    },
  },
  inputsCells: {
    0: { ...defaultInputsCellVision },
    1: { ...defaultInputsCellVision },
  },
  inferenceCells: {
    0: { 0: { ...defaultInferenceCell }, 1: { ...defaultInferenceCell } },
  },
};

export const newPideSessionDefaults = {
  name: 'New session name',
  sourceCode: DEFAULT_PIDE_FUNCTION,
  inputsCells: { 0: { ...defaultPIDEInputsCell } },
  logData: [[]],
};

export const ROLES = {
  user: 'User',
  assistant: 'Assistant',
  system: 'System',
  tool: 'Tool',
  function: 'Function',
};

export const getROLES = (model) => {
  if (
    !SUPPORTS_FUNCTIONS.has(model) ||
    model === 'mistralai/Mistral-7B-Instruct-v0.1'
  ) {
    return {
      user: 'User',
      assistant: 'Assistant',
      system: 'System',
    };
  }
  return ROLES;
};

export const EVALUATION_METRIC_TYPES = {
  webhook: 'Webhook',
  function: 'Function',
};

export const PLAN_NAME_TO_IDX = {
  free: 0,
  team: 1,
  team6: 1,
  team12: 1,
  enterprise: 2,
};

export const PLANS: Plan[] = [
  {
    name: 'Free',
    cta: 'Current Plan',
    desc: 'Everything necessary to get started.',
    price: 0,
    priceAnnual: 0,
    priceIdMonth: '',
    priceIdYear: '',
    isMostPop: false,
    features: [
      '2 members',
      'Prompt playground (+ function calling)',
      'Custom datasets and evaluation metrics',
      '10 deployed prompts',
      'Log and trace monitoring (1 mon retention)',
      '3k logged events / month',
      'Data exports',
      'Opt-in participation in pre-release and beta features',
    ],
    usageLimits: {
      deployedPrompts: 10,
      loggedEvents: 3000,
      members: 2,
    },
  },
  {
    name: 'Team',
    cta: 'Upgrade to Team',
    desc: 'For production apps and teams.',
    price: 150,
    priceAnnual: 1440,
    priceIdMonth: process.env.REACT_TEAM_ID,
    priceIdYear: process.env.REACT_TEAM_ANN_ID,
    isMostPop: true,
    features: [
      '3 members (+$50 per additional member upto 20)',
      'Everything in Free, plus:',
      'Collaborative workspace',
      `Batch evaluation jobs`,
      '100 deployed prompts',
      '100k logged events / month ($0.001 / extra log)',
      '3 month data retention, (6/12 mon upgrade)',
      '1:1 support + private Slack channel',
    ],
    usageLimits: {
      deployedPrompts: 100,
      loggedEvents: 100000,
      members: 20,
    },
  },
  {
    name: 'Enterprise',
    cta: 'Contact Us',
    desc: 'For at-scale organizations with advanced security, compliance and support needs.',
    price: 'Contact Us',
    priceAnnual: 'Contact Us',
    priceIdMonth: '',
    priceIdYear: '',
    isMostPop: false,
    features: [
      'Unlimited team members',
      'Everything in Team, plus:',
      'Unlimited logged events',
      'Unlimited deployed prompts',
      'Custom features',
      'Hosted models',
      'Roles & permissions & SSO',
      'PII redaction',
      'SOC 2, GDPR, ISO compliance (soon)',
      'Email / Slack alerts (soon)',
    ],
    usageLimits: {
      deployedPrompts: null,
      loggedEvents: null,
      members: null,
    },
  },
];

// FRONTEND ROUTES
export const HOME_ROUTE = '/';
export const SIGNIN_ROUTE = '/sign-in/*';
export const SIGNUP_ROUTE = '/sign-up/*';
export const SETTINGS_ROUTE = '/settings';
export const BILLING_ROUTE = '/settings/billing';
export const PROJECT_MANAGEMENT_ROUTE = '/settings/projects';
export const STUDIO_ROUTE = '/deployments';
export const SHARED_ROUTE = `${STUDIO_ROUTE}/shared`;
export const PIDE_ROUTE = '/pide';
export const PIDE_DETAILED_ROUTE = `${PIDE_ROUTE}/session`;
export const LAB_ROUTE = '/playground';
export const LAB_SESSION_DETAILED_ROUTE = `${LAB_ROUTE}/session`;
export const STUDIO_DETAILED_ROUTE = `${STUDIO_ROUTE}/detailed`;
export const STUDIO_SHARED_DETAILED_ROUTE = `${SHARED_ROUTE}/detailed`;
export const TEST_HUB_ROUTE = '/datasets';
export const DASHBOARD_ROUTE = `/logs`;
export const DASHBOARD_DETAILED_ROUTE = `${DASHBOARD_ROUTE}/detailed`;
export const ONBOARDING_ROUTE = '/onboarding';
export const ONBOARDING_PROMPTS_ROUTE = `${ONBOARDING_ROUTE}/prompts`;
export const ONBOARDING_CHAINS_AGENTS_ROUTE = `${ONBOARDING_ROUTE}/chains-agents`;
export const ONBOARDING_TESTING_ROUTE = `${ONBOARDING_ROUTE}/testing`;
export const ONBOARDING_MONITORING_ROUTE = `${ONBOARDING_ROUTE}/monitoring`;
export const EXPERIMENTS_ROUTE = '/experiments';
export const EVALUATIONS_ROUTE = '/evaluations';
export const SINGLE_EXPERIMENT_ROUTE = `${EXPERIMENTS_ROUTE}/:experimentName/:experimentId`;
export const EXPERIMENT_COMPARE_ROUTE = `${EXPERIMENTS_ROUTE}/p/:projectName/:experimentId`;
export const ANNOTATION_QUEUE_ROUTE = '/annotation-queue';
export const ANNOTATION_QUEUE_DETAILED_ROUTE = `${ANNOTATION_QUEUE_ROUTE}/:annotation_queue_id`;
export const ANNOTATION_CRITERION_ROUTE = `/annotation-criterion`;
export const ANNOTATION_CRITERION_DETAILED_ROUTE = `${ANNOTATION_CRITERION_ROUTE}/:annotation_criterion_id`;

export const makeCompareExperimentRoute = (projectName, experimentId) =>
  `${EXPERIMENTS_ROUTE}/p/${projectName}/${experimentId}`;

export const getPublicExperimentsRoute = (
  orgSlug = null,
  projectName = null
) => {
  const prefix = '/public-experiments';
  if (orgSlug && projectName) {
    return `${prefix}/${orgSlug}/${projectName}`;
  } else {
    return `${prefix}/:orgSlug/:projectName`;
  }
};
export const getPublicSingleExperimentRoute = (
  orgSlug = null,
  projectName = null,
  experimentId = null
) => {
  const prefix = getPublicExperimentsRoute(orgSlug, projectName);
  if (orgSlug && projectName && experimentId) {
    return `${prefix}/${experimentId}`;
  } else {
    return `${prefix}/:experimentId`;
  }
};
export const PUBLIC_LOGS_ROUTE = '/public-logs';

// ROUTE NAMES
export const LAB_NAME = 'Playground';
export const SHARED_NAME = 'Shared';
export const DEPLOYMENTS_NAME = 'Deployments';
export const TEST_HUB_NAME = 'Datasets';
export const EVALUATIONS_NAME = 'Evaluations';
export const OPTIMIZE_NAME = 'Optimize';
export const DASHBOARD_NAME = 'Logs';
export const PIDE_NAME = 'IDE';
export const EXPERIMENTS_NAME = 'Experiments';
export const ANNOTATIONS_NAME = 'Annotations';

// PIDE Example Session names
export const PIDE_EXAMPLE_SESSION_DEFAULT = 'default';
export const PIDE_EXAMPLE_SESSION_FUNCTION_CALLING = 'function_calling';

export const DEMO_VIDEO_LINKS = {
  pide: 'https://www.loom.com/share/b7e9a8a864934c79858fb4b8074aa783?sid=c47cb419-0cdd-4071-a77e-bbf1939314a3',
  lab: 'https://youtu.be/MQMNMUCzf78?si=w8c6pPhNxTj_NkgT',
  functions: '',
  testHub: 'https://youtu.be/IF973AgikqY?si=qR1PaMcViP4ZJKxe',
  logs: '',
};

export const DOC_LINKS = {
  playground: 'https://docs.parea.ai/platform/playground/compare',
  deployments: 'https://docs.parea.ai/platform/deployment',
  datasets: 'https://docs.parea.ai/platform/test_hub/overview',
  evalFunctions: 'https://docs.parea.ai/platform/playground/evaluation_metrics',
  monitoring: 'https://docs.parea.ai/welcome/getting-started',
  experiments: 'https://docs.parea.ai/welcome/getting-started-evaluation',
  annotationQueues: 'https://docs.parea.ai/manual-review/overview',
};

export const ROUTE_DETAILS = {
  Playground: {
    name: LAB_NAME,
    route: LAB_ROUTE,
    icon: (size) => <IoTerminal size={size} />,
    docs: DOC_LINKS.playground,
    demo: '',
  },
  Deployments: {
    name: DEPLOYMENTS_NAME,
    route: STUDIO_ROUTE,
    icon: (size) => <IoRocket size={size} />,
    docs: DOC_LINKS.deployments,
    demo: '',
  },
  Datasets: {
    name: TEST_HUB_NAME,
    route: TEST_HUB_ROUTE,
    icon: (size) => <FaDatabase size={size} />,
    docs: DOC_LINKS.datasets,
    demo: '',
  },
  Evaluations: {
    name: EVALUATIONS_NAME,
    route: EVALUATIONS_ROUTE,
    icon: (size) => <PiExamFill size={size} />,
    docs: DOC_LINKS.evalFunctions,
    demo: '',
  },
  Logs: {
    name: DASHBOARD_NAME,
    route: DASHBOARD_ROUTE,
    icon: (size) => <MdMonitorHeart size={size} />,
    docs: DOC_LINKS.monitoring,
    demo: '',
  },
  Experiments: {
    name: EXPERIMENTS_NAME,
    route: EXPERIMENTS_ROUTE,
    icon: (size) => <AiFillExperiment size={size} />,
    docs: DOC_LINKS.experiments,
    demo: '',
  },
  Annotations: {
    name: ANNOTATIONS_NAME,
    route: ANNOTATION_QUEUE_ROUTE,
    icon: (size) => <MdRateReview size={size} />,
    docs: DOC_LINKS.annotationQueues,
    demo: '',
  },
};
